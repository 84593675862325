import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { useProject } from "../hooks/useProjects";
import GalleryItem from "../components/project/GalleryItem";
import NextProjectButton from "../components/project/NextProjectButton";
import BeforeProjectButton from "../components/project/BeforeProjectButton";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useBackgroundColor } from "../contexts/BackgroundColorContext";

function Project() {
  const { id } = useParams();
  const { handleFetchProjectById, handleFetchProjects } = useProject();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);
  const [nextProject, setNextProject] = useState({});
  const [beforeProject, setBeforeProject] = useState({});
  const { setBackgroundColor } = useBackgroundColor();

  useEffect(() => {
    // Start loading
    setLoading(true);

    handleFetchProjects({ setLoading: () => {} })
      .then((data) => {
        const filteredData = data.filter((p) => !p.blank);
        const currentProjectIndex = filteredData.findIndex(
          (p) => p.title === id
        );
        setNextProject(
          filteredData[currentProjectIndex + 1] || filteredData[0]
        );
        setBeforeProject(
          filteredData[currentProjectIndex - 1] ||
            filteredData[filteredData.length - 1]
        );
        const currentProject = filteredData.find((p) => p.title === id) || {};
        setProject(currentProject);
        setBackgroundColor(currentProject.background || "#FFFFFF"); // Set a default color
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        // Optionally set an error state here
        setLoading(false);
      });

    window.scrollTo(0, 0);
  }, [id, handleFetchProjects, setBackgroundColor]);

  const loader = (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: "#FFFFFF",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <div>
        <Skeleton height={300} width="100%" />
        <Skeleton height={300} width="100%" />
        <Skeleton height={300} width="100%" />
        <Skeleton height={300} width="100%" />
        <Skeleton height={300} width="100%" />
      </div>
    </div>
  );

  if (loading) {
    return loader;
  }

  return (
    <div
      key={id}
      style={{
        backgroundColor: project.background || "#FFFFFF",
        minHeight: "100vh",
      }}
    >
      <CustomHelmet title={project.title} description={project.description} />
      <div
        className="container py-md-4 py-0 fadeIn"
        style={{ textAlign: "justify", textJustify: "inter-word" }}
      >
        <div dangerouslySetInnerHTML={{ __html: project.description }} />
        <section className="my-md-5">
          {project?.gallery?.map((resource, index) => (
            <div key={index} className="col-12">
              <GalleryItem resource={resource} index={index} />
            </div>
          ))}
        </section>
        <div className="d-flex justify-content-between">
          <BeforeProjectButton beforeProject={beforeProject} />
          <NextProjectButton nextProject={nextProject} />
        </div>
      </div>
    </div>
  );
}

export default Project;
