import React, { createContext, useContext, useState, useEffect } from "react";

const ConsentContext = createContext();

export const useConsent = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(
    localStorage.getItem("cookieConsent") || null
  );

  const acceptConsent = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setCookieConsent("accepted");
    // Initialize services here if needed
  };

  const declineConsent = () => {
    localStorage.setItem("cookieConsent", "declined");
    setCookieConsent("declined");
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setCookieConsent(localStorage.getItem("cookieConsent"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <ConsentContext.Provider
      value={{ cookieConsent, acceptConsent, declineConsent }}
    >
      {children}
    </ConsentContext.Provider>
  );
};
