import { useBackgroundColor } from "../../contexts/BackgroundColorContext";
import TranslatedLink from "../navigation/TranslatedLink";
import { useLang } from "../../translations/i18n";
import { RUTAS } from "../../constants/routes.constants";
import Instagram from "../../assets/instagram.png";

import "./Footer.css";

export default function Footer() {
  const { backgroundColor } = useBackgroundColor();
  const lang = useLang();

  return (
    <footer className="footer" style={{ backgroundColor }}>
      <div className="container">
        <div className="row align-items-center py-3">
          {/* Home */}
          <div className="col-6 col-md-3 mb-3 mb-md-0">
            <TranslatedLink to={RUTAS.HOME}>
              <span className="fw-bold">Home</span>
            </TranslatedLink>
          </div>

          {/* Contacto */}
          <div className="col-6 col-md-3 mb-1 mb-md-0">
            <TranslatedLink to={RUTAS.CONTACT}>
              <span className="fw-bold">Contacto</span>
            </TranslatedLink>
            <p className="mb-0">(+34) 635 90 60 42</p>
          </div>

          {/* Políticas */}
          <div className="col-6 col-md-3 mb-3 mb-md-0">
            <TranslatedLink to={RUTAS.PRIVACIDAD}>
              Política de privacidad
            </TranslatedLink>
            <br />
            <TranslatedLink to={RUTAS.COOKIES}>
              Política de Cookies
            </TranslatedLink>
          </div>

          {/* Redes Sociales */}
          <div className="col-6 col-md-3 text-center text-md-end">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="Instagram" className="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
