import "./App.css";
import { injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { setLanguage, useLang } from "./translations/i18n";
import Top from "./components/navigation/Top";
import Footer from "./components/footer/Footer";
import CookieConsentModal from "./components/cookies/CookieConsentModal";
import { ProjectsProvider } from "./contexts/ProjectsContext";
import { useBackgroundColor } from "./contexts/BackgroundColorContext";
import { useConsent } from "./contexts/ConsentContext";
import Skeleton from "react-loading-skeleton";
import { RUTAS } from "./constants/routes.constants";
import "react-loading-skeleton/dist/skeleton.css";

function App({ intl }) {
  const { pathname } = useLocation();
  const lang = useLang();
  const { backgroundColor } = useBackgroundColor();
  const { cookieConsent } = useConsent();

  useEffect(() => {
    const [_, url_lang] = pathname.split("/");
    if (url_lang === "ca" && lang !== "ca") setLanguage("ca");
    else if (url_lang === "en" && lang !== "en") setLanguage("en");
    else if (url_lang !== "ca" && url_lang !== "en" && lang !== "es")
      setLanguage("es");
  }, [lang, pathname]);

  const publicPaths = [RUTAS.COOKIES[lang], RUTAS.PRIVACIDAD[lang]];

  const isPublicRoute = publicPaths.includes(pathname);

  const loader = (
    <div
      style={{
        backgroundColor: backgroundColor,
        minHeight: "100vh",
        padding: "2rem",
      }}
    >
      <Skeleton height={60} width={300} />
      <Skeleton count={5} height={30} style={{ marginTop: "1rem" }} />
      <Skeleton height={200} style={{ marginTop: "2rem" }} />
      <Skeleton height={50} style={{ marginTop: "2rem" }} />
    </div>
  );

  return (
    <ProjectsProvider>
      <div style={{ backgroundColor: backgroundColor }}>
        <div className="App page-wrapper">
          <div className="content">
            {isPublicRoute || cookieConsent === "accepted" ? (
              <>
                <header style={{ position: "relative" }}>
                  <Top pathname={pathname} />
                </header>
                <Outlet />
              </>
            ) : (
              loader
            )}
            {!isPublicRoute && <CookieConsentModal />}
          </div>
          {(cookieConsent === "accepted" || isPublicRoute) && <Footer />}
        </div>
      </div>
    </ProjectsProvider>
  );
}

export default injectIntl(App);
