import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { postContact } from "../api/contact";
import { useInView } from "react-intersection-observer";
import { useLang } from "../translations/i18n";
import { alertError, alertSuccess } from "../utils/logger";
import { useBackgroundColor } from "../contexts/BackgroundColorContext";

import { getTexts } from "../api/text";

import contactlogo from "../assets/contactAmos.png";

function Contact({ intl }) {
  const lang = useLang();
  const { backgroundColor, setBackgroundColor } = useBackgroundColor();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });
  const [texto, setTexto] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e.target.privacy.checked) {
      alertError({
        customMessage: "Debe aceptar la política de privacidad",
      });
      return;
    }
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log("DATA", data);
    postContact(data)
      .then((res) => {
        alertSuccess({ customMessage: "Mensaje enviado correctamente" });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        alertError({ error: err, customMessage: "Error al enviar el mensaje" });
      });
  };

  useEffect(() => {
    getTexts().then((res) => {
      console.log(res.data);
      const text = res.data.filter(
        (d) => d.title.es === "CONTENIDO_FORMULARIO"
      );
      console.log(text);
      setTexto(text[0]);
    });
  }, []);

  useEffect(() => {
    setBackgroundColor("#FFFFFF");
  }, [backgroundColor]);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "CONTACT.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "CONTACT.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container fadeIn">
        <div className="row">
          <div
            className="my-3 text-justify"
            dangerouslySetInnerHTML={{ __html: texto?.content?.[lang] }}
          />
          <div className="col-12 mb-4">
            <div
              className="image-placeholder w-100"
              style={{ height: "500px", backgroundColor: "#ececec" }}
            ></div>
          </div>
          <div className="col-12 col-lg-6 mt-3 mb-5">
            <img src={contactlogo} alt="Contacto" className="w-50 py-3" />
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="mb-3">
                <input
                  type="text"
                  name="name"
                  placeholder="Nombre*"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="subject"
                  placeholder="Tema*"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <textarea
                  name="message"
                  placeholder="Mensaje*"
                  className="form-control"
                  rows="4"
                ></textarea>
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  name="privacy"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="privacy">
                  Acepto la{" "}
                  <a href="politica-de-privacidad">
                    <strong>política de privacidad</strong>
                  </a>
                </label>
                <button
                  type="submit"
                  className="float-end p-2"
                  style={{
                    backgroundColor: "#f1911f",
                    borderRadius: "0px",
                    color: "#ffffff",
                    width: "100px",
                  }}
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Contact);
