import { useState, useEffect } from "react";
import { useBackgroundColor } from "../../contexts/BackgroundColorContext";
import TranslatedLink from "./TranslatedLink";
import { FormattedMessage } from "react-intl";
import logo_left from "../../assets/logo-left.png";
import { RUTAS } from "../../constants/routes.constants";
import { useLogo } from "../../hooks/useLogos";
import { SERVER_URL } from "../../utils";
import { useLang } from "../../translations/i18n";
import "./Top.css";

const frequencyWeights = {
  "quite-frequent": 5,
  normal: 3,
  rarely: 1,
};

function getWeightedRandomLogo(logos) {
  const weightedLogos = [];
  logos.forEach((logo) => {
    const weight = frequencyWeights[logo.frequency];
    for (let i = 0; i < weight; i++) {
      weightedLogos.push(logo);
    }
  });
  const randomIndex = Math.floor(Math.random() * weightedLogos.length);
  return weightedLogos[randomIndex];
}

function Top({ pathname }) {
  const { backgroundColor, setBackgroundColor } = useBackgroundColor();
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logo, setLogo] = useState([]);
  const { handleFetchLogos } = useLogo();
  const lang = useLang();

  const handleNavCollapse = () => {
    if (window.innerWidth < 768) {
      setIsMenuOpen(!isMenuOpen);
      setTimeout(() => {
        setIsNavCollapsed(!isNavCollapsed);
        setBackgroundColor("#dfe0e1");
      }, 300);
    }
  };

  useEffect(() => {
    handleFetchLogos({ setLoading: () => {} }).then((data) => {
      const selectedLogo = getWeightedRandomLogo(data);
      setLogo(selectedLogo);
    });
  }, [handleFetchLogos]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768 && isNavCollapsed) {
        setIsNavCollapsed(false);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isNavCollapsed]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg apercubold top"
        style={{ backgroundColor }}
      >
        <div className="container">
          <div className="col d-flex justify-content-between align-items-center my-5">
            <div className="row">
              <TranslatedLink to={RUTAS.HOME} className="mr-auto">
                <img src={logo_left} alt="Logo" className="creAmos-logo" />
              </TranslatedLink>
            </div>
            <div className="row mx-2">
              <button
                className="navbar-toggler align-sef-center border-0 p-2"
                type="button"
                onClick={handleNavCollapse}
                aria-expanded={!isNavCollapsed}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div
            className={` collapse ${!isNavCollapsed ? "show" : ""}`}
            id="navbarNav"
          >
            <div className="d-none d-lg-flex">
              <div className="row align-items-lg-center justify-content-lg-end">
                <div className="col-12 col-lg-auto d-lg-flex justify-content-lg-end">
                  <TranslatedLink to={RUTAS.HOME}>
                    {logo && (
                      <img
                        key={logo._id}
                        src={`${SERVER_URL}/${logo.imgUrl}`}
                        alt="logo secundario"
                        className="logo"
                        style={{ width: "80px", height: "auto" }}
                      />
                    )}
                  </TranslatedLink>
                </div>
                <div className="col-12 col-lg-auto d-flex justify-content-center justify-content-lg-start mt-2 mt-lg-0 f30 arimo">
                  <TranslatedLink to={RUTAS.CONTACT}>
                    {pathname === RUTAS.CONTACT[lang] ? (
                      <span className="text-orange">
                        <FormattedMessage id="MENU.INFO" />
                      </span>
                    ) : (
                      <span className="hover-orange">
                        <FormattedMessage id="MENU.INFO" />
                      </span>
                    )}
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isNavCollapsed && (
        <div
          className={`mobile-menu-overlay show-mobile-menu-overlay ${
            isMenuOpen ? "fadeInNavbar" : "fadeOutNavbar"
          }`}
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="menu-content">
            <div className="col-12 col-lg-auto d-flex justify-content-center justify-content-lg-start mt-2 mt-lg-0 f30 arimo">
              <TranslatedLink to={RUTAS.CONTACT} onClick={handleNavCollapse}>
                {pathname === RUTAS.CONTACT[lang] ? (
                  <span className="text-orange">
                    <FormattedMessage id="MENU.INFO" />
                  </span>
                ) : (
                  <span className="hover-orange">
                    <FormattedMessage id="MENU.INFO" />
                  </span>
                )}
              </TranslatedLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Top;
