import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import logo from "../../assets/logo-left.png";
import { useConsent } from "../../contexts/ConsentContext";

import "./CookieConsentModal.css";

const CookieConsentModal = () => {
  const { cookieConsent, acceptConsent, declineConsent } = useConsent();

  useEffect(() => {
    if (cookieConsent === "accepted") {
      initConsent();
    }
  }, [cookieConsent]);

  const initConsent = () => {
    console.log("INITIALIZING COOKIE SERVICES");
  };

  return (
    <Modal
      show={!cookieConsent}
      onHide={() => {}}
      centered
      backdrop="static"
      keyboard={false}
      aria-labelledby="cookie-consent-modal"
      className="custom-modal"
      size="sm"
      dialogClassName="modal-90w"
      style={{ zIndex: 20000 }}
    >
      <Modal.Header className="border-0">
        <div className="text-center w-100">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid mb-2"
            style={{ maxWidth: "150px" }}
          />
          <h5 className="mb-0">
            Este sitio solicita consentimiento para usar tus datos
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p>
          Utilizamos cookies y tecnologías similares para proporcionar ciertas
          funciones, mejorar la experiencia del usuario y entregar contenido
          relevante para sus intereses. Dependiendo de su propósito, se pueden
          utilizar cookies de análisis y marketing además de las cookies
          técnicamente necesarias. Al hacer clic en "Consentir", usted declara
          su consentimiento al uso de las cookies mencionadas anteriormente.
          Puedes realizar ajustes detallados o revocar tu consentimiento (en
          parte si es necesario) en cualquier momento.
          <br />
          <br />
          Haz click{" "}
          <b>
            <a href="/politica-de-cookies">Aquí</a>
          </b>{" "}
          para más información.
          <br />
          <br />
          Consulte nuestra{" "}
          <a href="/politica-de-privacidad">
            <b>Política de Privacidad.</b>
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={declineConsent} className="cookie-button me-2">
          No consentir
        </Button>
        <Button onClick={acceptConsent} className="cookie-button">
          Consentir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieConsentModal;
